import React from 'react'
// import { useTranslation } from 'react-i18next';
import { useParams /*, useHistory */ } from 'react-router-dom'
import TopNavigation from '../../TopNavigation'
import { Container } from 'reactstrap'
import { useSelector } from 'react-redux'
import { Button, Chip, Divider, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import withAppconfig from '../../AppContext'
import { getLastvalidDate } from './MembershipUtil'
import { connect } from 'react-redux'
// import ProfileInput from '../../components/ProfileInput'
import Poster from '../../components/Poster'

const enhance = connect(({ globaltime: { gtime } }) => ({
  gtime
}))

function CardScreen({ appconfig, gtime }) {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [selectedVariant, setSelectedVariant] = React.useState(null)
  // const [profileIsValid, setProfileIsValid] = React.useState(false)
  const card = useSelector((state) => {
    return state.firebase.data.shopcards && state.firebase.data.shopcards[params.id]
  })

  if (card === undefined) return history.push(appconfig.pathPrefix + '/shop/cards')
  const { poster } = card
  let variationsSelector = null
  if (card.variations) {
    if (card.variations.length > 1) {
      variationsSelector = card.variations.map((v) => {
        return (
          <Chip
            clickable
            label={v.name}
            key={'varid-' + v.varid}
            color={selectedVariant?.varid === v.varid ? 'primary' : 'default'}
            variant={selectedVariant?.varid === v.varid ? 'outlined' : 'default'}
            onClick={() => setSelectedVariant(v)}
            style={{ margin: 5 }}
          />
        )
      })
    } else {
      variationsSelector = <Typography variant='h5'>{card.variations[0].name}</Typography>
      if (selectedVariant === null) {
        setSelectedVariant(card.variations[0])
      }
    }
  }

  const goToPay = (card) => {
      card.type = 'benefitcard'
      dispatch({
        type: 'UPDATE_PAYABLE_BENEFITCARD',
        payload: card,
      })
      history.push(appconfig.pathPrefix + '/cards/checkout')
  }

  let lastvalidDate = getLastvalidDate(card.validity, gtime)
  if (lastvalidDate === 'permanent') {
    lastvalidDate = t('permanent')
  } else {
    lastvalidDate = t('valid') + ' ' + lastvalidDate.format('D.M.YYYY')
  }

  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        <Grid container spacing={3} alignItems='flex-start' >
          <Grid item xs={12} md={6} style={{ textAlign: 'center', paddingTop: 40 }}>
            <Poster data={poster} preferredSize={300} alt={card.displayName} defaultBg={appconfig.logos} />
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingTop: '5em', paddingRight: 20 }}>
            <Typography variant='h4'>{card.displayName.toUpperCase()}</Typography>
            <Typography variant='button'>{lastvalidDate}</Typography>
            <Divider />
            {variationsSelector}
            <div>
              <Typography variant='h6'>
                {selectedVariant ? (selectedVariant.price / 100).toFixed(2).replace('.', ',') + ' €' : null}
              </Typography>
              <Typography variant='caption'>
                {selectedVariant ? t('vat') + ' ' + card.vat + ' %' : null}
              </Typography>
            </div>

            <Button
              variant='contained'
              color='primary'
              disabled={!selectedVariant}
              style={{ marginTop: 10, marginBottom: 10 }}
              onClick={() => goToPay({ ...card, selectedVariant })}
            >
              {t('buy')}
            </Button>
            <Divider />
            <Typography variant='body1'>
              <span dangerouslySetInnerHTML={{ __html: card.description }} />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default withAppconfig(enhance(CardScreen))
