import React from 'react'
import { Container, Row, Col, Alert } from 'reactstrap'
import TopNavigation from '../../TopNavigation'
import withAppconfig from '../../AppContext'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import MatchCard from '../../components/Match/MatchCard'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const mapStateToProps = (state, props) => {
  const { firebase } = state
  if (!firebase.ordered.matches) return { matches: null }
  const matches = firebase.ordered.matches.map((m) => {
    return { id: m.key, ...m.value }
  })
  return {
    matches,
  }
}

const withMatches = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firebaseConnect((props) => [
    {
      path: 'matches/' + props.appconfig.accountkey + '',
      // queryParams: ['orderByChild=displayName'],
      storeAs: 'matches',
    },
  ]),
  connect(mapStateToProps)
)


function MatchesScreen({ appconfig, matches, auth, gtime, dispatch}) {
  const { t } = useTranslation()
  const isAuth = auth.isLoaded && !auth.isEmpty
  let defaultLanguage = 'FI'
  const history = useHistory()


  const goToPayActivity = (match) => {
      dispatch({
        type: 'UPDATE_PAYABLE_ACTIVITY',
        payload: match,
      })
      history.push(appconfig.pathPrefix + '/bookactivity')
  }


  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        {!isAuth ? (
          <Alert color='warning'>{t('login_to_join_matches')}</Alert>
        ) : null}
        <Typography variant='h6' style={{ paddingBottom: 20 }}>
          {t('matches')}
        </Typography>
        {matches && matches.length > 0 ? (
          <Row>
            {matches.map((m) => (
              <Col key={m.id} xs={12} sm={4}>
                <MatchCard
                  match={m}
                  collection={'matches'}
                  isAuthRequired={true}
                  isAuth={isAuth}
                  lng={defaultLanguage}
                  paymentAction={goToPayActivity}
                  // cancelAction={confirmCancel}
                  // uid={auth.uid}
                  // shareClick={shareClick}
                  gtime={gtime}
                />
              </Col>
            ))}
          </Row>
        ) : null}
      </Container>
    </div>
  )
}

export default withAppconfig(withMatches(MatchesScreen))
