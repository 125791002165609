import React, { useState } from 'react'
import { isLoaded, useFirebase, firestoreConnect } from 'react-redux-firebase'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  Button,
  Spinner,
  Modal,
  ModalBody,
} from 'reactstrap'
import { Redirect, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
// import Checkbox from '@material-ui/core/Checkbox'

import moment from 'moment'
import 'moment/locale/fi'
import { Divider } from '@material-ui/core'
import PaymentRedirect from '../PaymentRedirect'
import PaymentOptions from '../PaymentOptions'
import { useEffect } from 'react'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import withAppconfig from '../../AppContext'
import Payments from '../../components/Payments'
import { getWallet } from '../../components/ViewUtils'
import Giftcode from '../../components/Giftcode'
import { getLastvalidDate } from './MembershipUtil'

const enhance = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'wallets',
    },
  ]),
  connect(({ payable: { selected }, firebase: { auth, profile }, firestore, globaltime: { gtime } }, { appconfig }) => {
    if (selected) {
      // console.log('selected', selected)
      const summary = { price: 0 }
      let wallet = []
      const walletName = getWallet(appconfig, summary)
      if (firestore.data['wallets'] && firestore.data['wallets'][walletName]) {
        wallet = firestore.data['wallets'][walletName]
      }
      const cashsum =
        wallet && wallet.cash
          ? wallet.cash.reduce((acc, curr) => {
              acc = acc + curr.amount
              return acc
            }, 0)
          : 0

      const sportmoneysum =
        wallet && wallet.sportmoney
          ? wallet.sportmoney.reduce((acc, curr) => {
              acc = acc + curr.amount
              return acc
            }, 0)
          : 0

      return {
        auth,
        profile,
        selected,
        summary,
        cashsum,
        sportmoneysum,
        wallet: firestore.data['wallets'] || [],
        memberships: [],
        gtime
      }
    } else {
      return {
        auth,
        profile,
        selected,
        summary: {},
        cashsum: 0,
        sportmoneysum: 0,
        memberships: [],
        gtime
      }
    }
  })
)

const UNALLOWED_METHODS = ['cashier', 'cashstore', 'minutestore', 'cashwallet', 'benefitcard']

const CardPaymentScreen = ({
  auth,
  profile,
  selected,
  summary,
  cashsum,
  sportmoneysum,
  wallet,
  dispatch,
  appconfig,
  memberships,
  gtime
}) => {
  const firebase = useFirebase()
  let history = useHistory()
  let defaultLanguage = 'FI'
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [renderPayments, setRenderPayments] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState(null)
  // const [payments, setPayments] = useState(null)
  const [errorNotification, setErrorNotification] = useState(null)
  const [onlinepaymentprovider, setOnlinePaymentProvider] = useState(null)
  const [initdone, setInitDone] = useState(false)
  // const [usernote, setUserNote] = useState(null)
  const [membershipprices, setMembershipPrices] = useState(null)
  const [giftcoderedeem, setGiftcoderedeem] = useState(null)
  const [pricescheck, setPricesCheck] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }
  // console.log('saldo', saldo, isLoaded(saldo))

  useEffect(() => {
    moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
  }, [defaultLanguage])
  const loaded = isLoaded(profile) && isLoaded(wallet) && selected

  if (loaded && !initdone) {
    setInitDone(true)
  }

  const goCards = () => {
    dispatch({
      type: 'CLEAR_PAYABLE_BENEFITCARD',
      payload: null,
    })
    history.push(appconfig.pathPrefix + '/shop/cards')
  }

  const setPaymentProvider = (p) => setOnlinePaymentProvider(p)

  const payCard = async (selectedPayments) => {
    setLoading(true)
    const payableObject = {
      id: selected.id,
      customerid: appconfig.accountkey,
      paymentmethod: selectedPayments,
      // usernote: usernote,
      variation: selected.selectedVariant,
    }

    if (giftcoderedeem) {
      payableObject.giftcode = giftcoderedeem
    }

    const shouldRenderOptions =
      selected.selectedVariant.price > 0 ? selectedPayments.find((x) => x.type === 'online' && x.charged > 0) : false
    const shouldRedict = selected.selectedVariant.price > 0 ? selectedPayments.find((x) => x.type === 'online' && x.charged > 0) : false
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testcards-pay' : 'cards-pay'
    const cardPayment = firebase.functions().httpsCallable(fn)
    try {
      let response = await cardPayment(payableObject)
      // console.log(response.data)
      if (response.data.error) {
        setLoading(false)
        setErrorNotification(response.data.error)
      } else if (shouldRenderOptions) {
        setRenderPayments(true)
      } else if (shouldRedict) {
        setRedirect(true)
      } else {
        setLoading(false)

        history.push(appconfig.pathPrefix + '/shop/cards')
      }
    } catch (error) {
      alert(error.message)
      // console.log(error)
      setLoading(false)
    }
  }

  // const getNoteRow = () => {
  //   if (appconfig.activityextrafields) {
  //     return (
  //       <React.Fragment>
  //         <Divider />
  //         <Row className={'loose'}>
  //           <Col>
  //             <FormGroup className='loose-form'>
  //               <Label for='usernote'>{t('additionalinfo')}</Label>
  //               <Input
  //                 type='text'
  //                 name='usernote'
  //                 id='usernote'
  //                 value={usernote || ''}
  //                 placeholder=''
  //                 onChange={(event) => setUserNote(event.target.value)}
  //               />
  //             </FormGroup>
  //           </Col>
  //         </Row>
  //       </React.Fragment>
  //     )
  //   } else {
  //     return null
  //   }
  // }

  const updateItemPricing = (prices) => {
    if (prices && prices.length > 0) {
      prices.sort((a, b) => a.price - b.price)
    }
    let membershipids =  memberships.map((v) => v.membershipid ) //Object.keys(memberships || {}).map((key) => {
    //   return memberships[key].membershipid
    // })
    let _membershipprices = prices.filter((x) => {
      if (x.membershipid) {
        return membershipids.includes(x.membershipid)
      } else {
        return true
      }
    })
    // console.log('membershipprices', _membershipprices, 'selected', { ...selected })
    const originalPrice = selected.price
    if (_membershipprices && _membershipprices.length > 0) {
      selected.price = _membershipprices[0].price
    }
    if (_membershipprices.length > 0) {
      selected.overridecost = true
      const gen = {
        pricing: 'general', price: originalPrice
      }
      _membershipprices.push(gen)
      // membershipprices.find((x) => x.pricing === 'general')
      // selected.general = gen
    }
    setMembershipPrices(_membershipprices)
  }

  const onValidGiftcode = (membershipprices, giftcode) => {
    setGiftcoderedeem(giftcode)
    updateItemPricing(membershipprices)
  }

  const cancelEntry = async (id) => {}

  if (!selected) {
    return <Redirect to='/' />
  }

  if (renderPayments) {
    return (
      <PaymentOptions
        selectedGw={'pc'}
        authorization={token}
        provider={onlinepaymentprovider}
        disabledmethods={appconfig.disabledpaymentmethods || []}
        onCancel={cancelEntry}
        cancellable={{ id: selected.id, type: 'membership' }}

      />
    )
  } else if (redirect) {
    return <PaymentRedirect selectedGw={'pc'} authorization={token} />
  }

  if (!loaded) {
    return (
      <div className='full-page-background'>
        <Container className='login-dialog'>
          <Spinner /> Loading ...
        </Container>
      </div>
    )
  } else {
    
    let lastvalidDate = getLastvalidDate(selected.validity, gtime)
    if (lastvalidDate === 'permanent') {
      lastvalidDate = t('permanent')
    } else {
      lastvalidDate = lastvalidDate.format('D.M.YYYY')
    }
    let unallowedmethods = UNALLOWED_METHODS
    if (appconfig && appconfig.experimental && appconfig.experimental.paycardsfromsaldo) {
      unallowedmethods = ['cashier', 'cashstore', 'minutestore', 'benefitcard']
    }

    return (
      <div className='full-page-background'>
        <Container className='login-dialog'>
          <Row>
            <Col md='8' className='mx-auto'>
              <Card>
                <CardHeader tag='h4'>{t('payment')}</CardHeader>
                <CardBody>
                  <CardTitle tag='h5'>{selected.displayName}</CardTitle>
                  <Row className={'loose'}>
                    <Col>{t('valid')}</Col>
                    <Col style={{ textTransform: 'capitalize' }} className={'text-right'}>
                      {lastvalidDate}
                    </Col>
                  </Row>
                  <Divider />
                  {pricescheck ? (
                    <Spinner size='sm' />
                  ) : membershipprices ? (
                    membershipprices.map((p, i) => {
                      return (
                        <Row key={'membership-prices-' + i} className={'loose'}>
                          <Col>{p.pricing === 'general' ? t('price') : p.pricing}</Col>
                          <Col className={'text-right'}>
                            {i >= 1 ? (
                              <s>{p.price.toFixed(2).replace('.', ',')} €</s>
                            ) : (
                              p.price.toFixed(2).replace('.', ',') + ' €'
                            )}
                          </Col>
                        </Row>
                      )
                    })
                  ) : (
                    <Row className={'loose'} key={'price'}>
                      <Col>{t('price')}</Col>
                      <Col className={'text-right'}>{(selected.selectedVariant.price / 100).toFixed(2).replace('.', ',')} €</Col>
                    </Row>
                  )}

                  <Divider />
                  <Row className={'loose'}>
                    <Col xs={4} sm={3}>
                      <Giftcode
                        selected={selected}
                        membershipprices={membershipprices}
                        pricesCheckState={setPricesCheck}
                        onValidGiftcode={onValidGiftcode}
                        producttype='activity'
                      />
                    </Col>
                  </Row>
                  <CardText></CardText>
                  <Payments
                    onlyfullpayment={true}
                    price={selected.selectedVariant.price / 100}
                    selecttype='payable'
                    unallowedmethods={unallowedmethods}
                    buttonText={selected.selectedVariant.price > 0 ? t('pay') : t('confirm')}
                    buttonAction={payCard}
                    errorNotification={errorNotification}
                    setPaymentProvider={setPaymentProvider}
                    ready={true}
                    nooverridemetohods={unallowedmethods}
                  />
                  <Button size='small' color='link' className='text-muted' onClick={() => goCards()}>
                    <ChevronLeftIcon /> {t('back_to_cards')}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          size='sm'
          centered
          isOpen={loading}
          backdrop={true}
          wrapClassName={'al-wrap'}
          modalClassName={'al-modal'}
          contentClassName={'loading-spinner'}
        >
          <ModalBody className='text-center'>
            <Spinner color='light' /> <p style={{ color: 'white' }}>Vahvistetaan</p>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default withAppconfig(enhance(CardPaymentScreen))
