import React, { useEffect, useState } from 'react'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Button } from 'reactstrap'
import Box from '@material-ui/core/Box'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { isEmpty } from 'react-redux-firebase'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import ReactFlagsSelect from 'react-flags-select'
import useWindowDimensions from './useWindowsDimensions'
import withAppconfig from './AppContext'
import BottomNav from './BottomNav'

// import logo from './assets/logo_evs.png'

// const inIframe = () => {
//   try {
//     return window.self !== window.top
//   } catch (e) {
//     return true
//   }
// }

const enhance = connect(({ firebase: { auth, profile } }) => ({
  auth,
  profile,
}))

const useBottom = false

function TopNavigation({ auth, profile, appconfig }) {
  const { t, i18n } = useTranslation()
  let defaultLanguage = 'FI'
  const history = useHistory()
  const { width } = useWindowDimensions()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const isLoggedIn = !isEmpty(auth)
  const [cookies, setCookie] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }

  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage)
  let title = width > 1000 ? appconfig.title.long : appconfig.title.short

  const changeLanguage = (lng) => {
    let i18lang = lng === 'GB' ? 'en' : lng.toLowerCase()
    i18n.changeLanguage(i18lang.toLowerCase())
    setSelectedLanguage(lng)
    setCookie('selectedLanguage', lng, { path: '/', maxAge: 60000, secure: true })
  }

  useEffect(() => {
    if (defaultLanguage) {
      let i18lang = defaultLanguage === 'GB' ? 'en' : defaultLanguage.toLowerCase()
      i18n.changeLanguage(i18lang.toLowerCase())
    }
  }, [defaultLanguage, i18n])

  let navLogo = <></>
  if (appconfig.logos) {
    let logo = appconfig.logos.find((l) => l.loc === 'navigation')
    if (!logo) {
      logo = appconfig.logos.find((l) => l.loc === 'default')
    }
    if (!logo) {
      navLogo = <img height='40px' src='https://cintoia.com/logo/default.svg' alt='Cintoia' />
    } else {
      if (logo.type === 'url') {
        navLogo = <img height='40px' src={logo.value} alt={logo.alt} />
      }
    }
  }

  if (history.location.search === '?_iframe') {
    return <></>
  }

  let topXs = {}
  if (useBottom) {
    topXs = {
      display: topXs,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9999,
      marginBottom: 'env(safe-area-inset-bottom)',
    }
  }

  return (
    <>
      <Box sx={topXs} elevation={3}>
        <Navbar color='white' light expand='md'>
          <NavbarBrand onClick={() => history.push(appconfig.pathPrefix + '/')}>
            {navLogo} {title}
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className='mr-auto' navbar>
              <NavItem>
                <NavLink onClick={() => history.push(appconfig.pathPrefix + '/')}>{t('vuoronvaraus')}</NavLink>
              </NavItem>
              {appconfig.activities ? (
                <>
                  <NavItem>
                    <NavLink onClick={() => history.push(appconfig.pathPrefix + '/activities/all')}>
                      {t('activities')}
                    </NavLink>
                  </NavItem>
                </>
              ) : null}
              {appconfig.allowcreatematches ? (
                <NavItem>
                  <NavLink onClick={() => history.push(appconfig.pathPrefix + '/matches')}>
                    {t('participate_matches')}
                  </NavLink>
                </NavItem>
              ) : null}
              {appconfig.memberships ? (
                <NavItem>
                  <NavLink onClick={() => history.push(appconfig.pathPrefix + '/shop/memberships')}>
                    {t('shop_memberships')}
                  </NavLink>
                </NavItem>
              ) : null}
              {appconfig.benefitcards ? (
                <NavItem>
                  <NavLink onClick={() => history.push(appconfig.pathPrefix + '/shop/cards')}>
                    {t('shop_cards')}
                  </NavLink>
                </NavItem>
              ) : null}
              {appconfig.recurringsales ? (
                <NavItem>
                  <NavLink onClick={() => history.push(appconfig.pathPrefix + '/recs/all')}>
                    {t('recurringsales')}
                  </NavLink>
                </NavItem>
              ) : null}
              {appconfig.leagues ? (
                <NavItem>
                  <NavLink onClick={() => history.push(appconfig.pathPrefix + '/leagues')}>{t('leagues')}</NavLink>
                </NavItem>
              ) : null}
              {appconfig.applications ? (
                <NavItem>
                  <NavLink onClick={() => history.push(appconfig.pathPrefix + '/applications/all')}>
                    {t('applications')}
                  </NavLink>
                </NavItem>
              ) : null}
              {isLoggedIn ? (
                <>
                  <NavItem>
                    <NavLink onClick={() => history.push(appconfig.pathPrefix + '/reservations')}>
                      {t('omat_varaukset')}
                    </NavLink>
                  </NavItem>
                  {appconfig.activities ? (
                <NavItem>
                  <NavLink onClick={() => history.push(appconfig.pathPrefix + '/ownactivities')}>
                    {t('own_activities')}
                  </NavLink>
                </NavItem>
              ) : null}
                  <NavItem>
                    <NavLink onClick={() => history.push(appconfig.pathPrefix + '/profile')}>
                      {t('omat_tiedot')}
                    </NavLink>
                  </NavItem>
                </>
              ) : null}
              {appconfig.navs
                ? appconfig.navs.map((x, i) => (
                    <NavItem key={'onavs' + i}>
                      {x.ext ? (
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          className='nav-link d-flex align-items-center'
                          href={x.url}
                        >
                          {x.image ? <img height='25px' src={x.image} alt={x.title} /> : x.title}
                        </a>
                      ) : (
                        <NavLink onClick={() => history.push(x.url)} className='d-flex align-items-center'>
                          {x.image ? <img height='25px' src={x.image} alt={x.title} /> : x.title}
                        </NavLink>
                      )}
                    </NavItem>
                  ))
                : null}
            </Nav>
            <Nav className='ml-auto' navbar>
              <div className='menu-flags'>
                <ReactFlagsSelect
                  selected={selectedLanguage}
                  onSelect={(code) => changeLanguage(code)}
                  countries={['FI', 'SE', 'GB', 'EE']}
                  customLabels={{
                    FI: { primary: 'Suomi' },
                    SE: { primary: 'Svensk' },
                    GB: { primary: 'English' },
                    EE: { primary: 'Eesti' },
                    // RU: { primary: 'Русский' },
                  }}
                  showSelectedLabel={true}
                  showOptionLabel={true}
                  selectedSize={16}
                  selectButtonClassName='menu-flags'
                  // className='menu-flags'
                />
              </div>
              {isLoggedIn ? (
                <NavItem>
                  <NavLink onClick={() => history.push(appconfig.pathPrefix + '/login')}>{profile.email}</NavLink>
                </NavItem>
              ) : (
                <>
                  <NavItem>
                    {isOpen ? (
                      <NavLink onClick={() => history.push(appconfig.pathPrefix + '/login')}>{t('kirjaudu')}</NavLink>
                    ) : (
                      <Button color='primary' onClick={() => history.push(appconfig.pathPrefix + '/login')}>
                        {t('kirjaudu')}
                      </Button>
                    )}
                  </NavItem>
                  <NavItem>
                    {isOpen ? (
                      <NavLink onClick={() => history.push(appconfig.pathPrefix + '/register')}>
                        {t('rekisteroidy')}
                      </NavLink>
                    ) : (
                      <Button
                        style={{ marginLeft: 10 }}
                        color='secondary'
                        onClick={() => history.push(appconfig.pathPrefix + '/register')}
                      >
                        {t('rekisteroidy')}
                      </Button>
                    )}
                  </NavItem>
                </>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </Box>
      {useBottom && <BottomNav />}
    </>
  )
}

export default withAppconfig(enhance(TopNavigation))
